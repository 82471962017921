<style>

</style>
<script>
import { VclBulletList, VclFacebook, VclCode, VclList } from 'vue-content-loading';

import CopyableText from "@/components/CopyableText";

export default {
  props: ['banlist_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    VclList,
    VclBulletList,
    Protocol: () => import('@/components/lazy/banmanager/view/desktop/ProtocolSmall'),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    gryphonChartOptions() {
      return {}
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/overview`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.banlist = data.banlist;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      banlist: null
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-2 text-right">
                      <h3>
                        {{ $n(banlist.metrics.bans) }}
                      </h3>
                      <p class="text-muted mb-0">
                        {{ $t('banmanager.view.overview.bans') }}
                      </p>
                    </div>
                    <div class="col-lg-2 text-right">
                      <h3>
                        {{ $n(banlist.metrics.followers) }}
                      </h3>
                      <p class="text-muted mb-0">
                        {{ $t('banmanager.view.overview.followers') }}
                      </p>
                    </div>
                    <div class="col-lg">
                      <div class="h-100 mt-3">
                        <div class="row ">
                          <div class="col text-center">
                            <h4 class="text-uppercase d-inline">
                              {{ $t('banmanager.view.overview.code') }}
                            </h4>
                            <h5 class="ml-2 d-inline">
                              <CopyableText :text="banlist.uuid" class="text-code" />
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Row two -->
          <div class="row">
            <div class="col-lg-6" v-if="ready && options.permissions.protocol === true">
              <Protocol :banlist_id="banlist_id"/>
            </div>
            <div class="col-lg-6">

            </div>
            <!--
            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">cloudstorage</h4>
                  <div class="mb-0">
                    <apexchart
                        class="apex-charts"
                        type="radialBar"
                        height="200"
                        dir="ltr"
                        :series="[]"
                        :options="gryphonChartOptions()"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="row">
                <div class="col-lg-6">

                </div>
                <div class="col-lg-6">

                </div>
              </div> -->
      </div>
    </template>
  </div>
</template>
